import classNames from 'classnames'
import { differenceInCalendarYears, parse } from 'date-fns'
import _ from 'lodash'
import React from 'react'
import UserStats from 'assessments/components/UserStats'
import AuthButton from 'components/AuthButton'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import ContactButton from 'components/ContactButton'
import Container from 'components/Container'
import Copy from 'components/Copy'
import Head from 'components/Head'
import Layout from 'components/Layout'
import Row from 'components/Row'
import Text from 'components/Text'
import View from 'components/View'
import * as events from 'constants/events'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as assessments from 'libs/assessments'
import * as assessmentsApiRequest from 'libs/assessments-api-request'
import * as user from 'libs/user'
import styles from './AssessmentResults.module.scss'

type Props = {
  assessmentInternalId: string
}

export default function AssessmentResults({ assessmentInternalId }: Props) {
  useEventPageVisited('Assessment results', { id: assessmentInternalId })
  const globalContext = useGlobalContext()
  const [data, setData] = React.useState({})

  const assessment = assessments.getByInternalId(assessmentInternalId)
  const locallyStoredAssessment = assessments.getLocallyStoredAssessmentDetails()
  const userId = globalContext.user?.id

  React.useEffect(() => {
    async function getCohortUser() {
      const response = await assessmentsApiRequest.generateAssessmentResult({
        age: differenceInCalendarYears(
          new Date(),
          parse(locallyStoredAssessment.dateOfBirth, 'MM/dd/yyyy', new Date())
        ),
        assessmentId: assessment.id,
        record: locallyStoredAssessment.result,
        userId,
      })

      setData(response)
    }
    getCohortUser()
  }, [assessment.id, locallyStoredAssessment.dateOfBirth, locallyStoredAssessment.result, userId])

  function handleSignUpClick() {
    globalContext.analytics?.trackEvent(events.SAVE_ASSESSMENT, {
      assessmentId: assessment.id,
      assessmentName: assessment.title,
    })
  }

  if (_.isEmpty(data)) return null

  return (
    <Layout className={classNames('AssessmentResults', styles.this)}>
      <Head title={`${assessment.title} assessment results`} />
      <div className={styles.inner}>
        <View color="grey" className={styles.main}>
          <Row flush={globalContext.isAuthed} size="large">
            <Text align="center" element="div">
              <Row size="large">
                <Container flush size="medium">
                  <Row size="small">
                    <Text color="white" element="div" className={styles.icon}>
                      <assessment.icon />
                    </Text>
                  </Row>
                  <Row size="xsmall">
                    <Text casing="upper" element="h1" size="xlarge" weight="semibold">
                      About your results
                    </Text>
                  </Row>
                  <Text element="p" flush>
                    Fitness assessments are a great tool to help you define your starting point, so
                    you can track your progress over time.
                  </Text>
                </Container>
              </Row>
              <Row size="large">
                <UserStats
                  assessmentInternalId={assessmentInternalId}
                  {...data}
                  points={locallyStoredAssessment.result}
                />
              </Row>
              {user.isEnterpriseOctopusUser(globalContext.user) ? (
                <Buttons align="center">
                  <Button to={paths.getProgramPath(globalContext.user)} color="yellow">
                    Go to my program
                  </Button>
                  <Button
                    to={`${paths.ASSESSMENTS}${assessmentInternalId}/${paths.ASSESSMENT_EXERCISE_RELATIVE}`}
                    level="tertiary">
                    Try again
                  </Button>
                </Buttons>
              ) : (
                <Buttons align="center">
                  <Button
                    to={`${paths.ASSESSMENTS}${assessmentInternalId}/${paths.ASSESSMENT_EXERCISE_RELATIVE}`}
                    color="yellow">
                    Try again
                  </Button>
                  <Button to={paths.ASSESSMENTS} color="yellow">
                    Take another test
                  </Button>
                </Buttons>
              )}
            </Text>
          </Row>
          {!globalContext.isAuthed && (
            <div className={styles.contact}>
              Still unsure about how Bold can help?{' '}
              <Text weight="semibold">
                <ContactButton color="purple" level="text" className={styles['contact--button']} />
              </Text>
            </div>
          )}
        </View>
        {!globalContext.isAuthed && (
          <Text align="center" element="section" className={styles.sidebar}>
            <div className={styles['sidebar--body']}>
              <Row size="xsmall">
                <Text casing="upper" element="h1" size="xlarge" weight="semibold">
                  How Bold can help
                </Text>
              </Row>
              <Text element="p">
                At Bold, our mission is to help you age well. Improve your Balance, Strength &
                Mobility from home with Bold’s online, easy-to-follow exercise programs.
              </Text>
              <Copy align="left" className={styles.copy}>
                <Text element="ol">
                  <Text element="li">
                    <Text element="p">
                      <Text element="b">Get science-backed recommendations.</Text> Let Bold do the
                      complicated research on your behalf to find the best exercises that will help
                      you achieve your healthy aging goals.
                    </Text>
                  </Text>
                  <Text element="li">
                    <Text element="p">
                      <Text element="b">Feel good in your body.</Text> Getting stronger and
                      healthier means feeling your best during other moments too - whether that’s
                      during walks with friends, spending time with family, or on vacation.
                    </Text>
                  </Text>
                  <Text element="li">
                    <Text element="p">
                      <Text element="b">Invest in your health as you age.</Text> Every minute
                      counts, and even if you only have 10 minutes to give, Bold’s classes make sure
                      you get the greatest impact for your investment of time.
                    </Text>
                  </Text>
                </Text>
              </Copy>
            </div>
            <AuthButton color="yellow" full onClick={handleSignUpClick}>
              Create account to save score
            </AuthButton>
          </Text>
        )}
      </div>
    </Layout>
  )
}
