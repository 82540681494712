import classNames from 'classnames'
import React from 'react'
import Button from 'components/Button'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import GlobalContext from 'contexts/GlobalContext'
import styles from './AssessmentStopwatch.module.scss'
import stopwatchAudio from './audio/stopwatch.mp3'
import { ReactComponent as RingSvg } from './images/ring.svg'

type Props = {
  audioProps: {
    muted?: boolean
    src?: string
  }
  // eslint-disable-next-line no-unused-vars
  onStop: (value: string) => void
} & Component

type State = {
  milliseconds: number
  seconds: number
  stoppedDuration: number
}

export default class AssessmentStopwatch extends React.Component<Props, State> {
  // eslint-disable-next-line react/sort-comp
  constructor(props: Props) {
    super(props)

    this.state = {
      seconds: 0,
      milliseconds: 0,
      stoppedDuration: 0,
    }

    this.incrementer = null
    this.mainRef = React.createRef()
    this.timeBegan = new Date()
  }

  static contextType = GlobalContext

  componentDidMount() {
    this.mainRef.current.addEventListener('keydown', (event) => {
      this.handleKeydown(event)
    })
    this.mainRef.current.focus()

    // eslint-disable-next-line unicorn/no-this-assignment
    const _this = this

    this.incrementer = setInterval(() => {
      _this.clockRunning()
    }, 10)
  }

  componentWillUnmount() {
    clearInterval(this.incrementer)
  }

  handleKeydown = (event) => {
    if (event?.code !== 'Space') return

    this.stop()
  }

  handleStopClick = () => {
    this.stop()
  }

  // false positive
  // eslint-disable-next-line react/no-unused-class-component-methods
  clockRunning = () => {
    const currentTime = new Date()
    const timeElapsed = new Date(currentTime - this.timeBegan - this.state.stoppedDuration)
    let seconds = timeElapsed.getUTCSeconds()
    let milliseconds = timeElapsed.getUTCMilliseconds()

    seconds = Math.floor((currentTime - this.timeBegan) / 1000)
    seconds = seconds > 9 ? seconds : `0${seconds}`
    milliseconds =
      milliseconds > 99 ? milliseconds : milliseconds > 9 ? `0${milliseconds}` : `00${milliseconds}`

    this.setState({
      milliseconds,
      seconds,
    })
  }

  stop = () => {
    const { milliseconds, seconds } = this.state

    clearInterval(this.incrementer)

    this.props.onStop(`${Number.parseInt(seconds, 10)}.${milliseconds}`)
  }

  render() {
    const {
      //milliseconds,
      seconds,
    } = this.state

    return (
      <div
        ref={this.mainRef}
        //tabIndex="0"
        className={classNames('AssessmentStopwatch', styles.this, this.props.className)}>
        <audio src={stopwatchAudio} {...this.props.audioProps} autoPlay loop />
        <div className={styles.timer}>
          <RingSvg />
          <div className={styles.time}>
            <Text className={styles['time--seconds']}>{seconds}</Text>
            {/*
            <Text wrap={false} className={styles['time--milliseconds']}>
              .{Math.round(milliseconds / 100)}
            </Text>
            */}
          </div>
        </div>
        <Button color="purple" size="xlarge" onClick={this.handleStopClick}>
          Stop
        </Button>
      </div>
    )
  }
}
