import classNames from 'classnames'
import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import Button from 'components/Button'
import Container from 'components/Container'
import Copy from 'components/Copy'
import Head from 'components/Head'
import Image from 'components/Image'
import Layout from 'components/Layout'
import ModalConfirm from 'components/ModalConfirm'
import Row from 'components/Row'
import Text from 'components/Text'
import Video2 from 'components/Video2'
import View from 'components/View'
import * as events from 'constants/events'
import paths from 'constants/paths'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as assessments from 'libs/assessments'
import * as checkinApiRequest from 'libs/checkin-api-request'
import * as notifications from 'libs/notifications'
import * as url from 'libs/url'
import * as user from 'libs/user'
import CheckinTracker from 'libs/checkins/checkin-tracker'
import OnboardingCheckinSingleForm from 'onboarding/components/OnboardingCheckinSingleForm'
import styles from './AssessmentLanding.module.scss'
import { ReactComponent as ArrowCircleSvg } from './images/arrow---circle.svg'

type Props = {
  assessmentInternalId: string
} & Component

export default function AssessmentLanding({ assessmentInternalId, className, ...props }: Props) {
  useEventPageVisited('Assessment landing', { id: assessmentInternalId })
  const globalContext = useGlobalContext()
  const [hasVideoEnded, setHasVideoEnded] = React.useState(false)
  const [hasVideoStarted, setHasVideoStarted] = React.useState(false)
  const [showVideo, setShowVideo] = React.useState(false)
  const [showRetakeModal, setShowRetakeModal] = React.useState(false)
  const [checkinTracker, setCheckinTracker] = React.useState({})

  React.useEffect(() => {
    const { retake } = url.getQueryString()

    if (retake) {
      setShowRetakeModal(true)
    }
  }, [])

  const assessment = assessments.getByInternalId(assessmentInternalId)
  const isMovementAssessment =
    assessment.internalId === assessments.ASSESSMENT_MOVEMENT_LOWER_BODY_INTERNAL_ID

  React.useEffect(() => {
    if (!globalContext.user) return

    async function getCheckin() {
      const response = await checkinApiRequest
        .getUserCheckin({
          activeOnly: true,
          type: 'assessment',
          code: 'lower_body_movement_assessment',
          createIfNotExists: true,
        })
        .catch((error) => {
          globalContext.analytics?.trackEvent(events.FAILED_CHECKIN_CALL, {
            error,
            from: paths.ASSESSMENTS,
            type: 'assessment',
          })
          notifications.notifyError()
        })

      const { data } = response
      setCheckinTracker(new CheckinTracker(globalContext.user.id, data, globalContext.analytics))
    }
    if (isMovementAssessment) getCheckin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalContext.user?.id, globalContext.analytics])

  React.useEffect(() => {
    if (_.isEmpty(assessment)) return

    globalContext.analytics?.trackEvent(events.OPEN_ASSESSMENT, {
      assessmentId: assessment.id,
      assessmentName: assessment.title,
    })
  }, [assessment, globalContext.analytics])

  function handlePlayClick() {
    setShowVideo(true)
    if (!hasVideoStarted) setHasVideoStarted(true)
  }

  function handleVideoEnded() {
    setHasVideoEnded(true)
    setShowVideo(false)
  }

  function closeRetakeModal() {
    setShowRetakeModal(false)
  }

  async function handleSuccess() {
    notifications.notifySuccess('Look at you go!')
    navigate(paths.ASSESSMENTS, { replace: true })
  }

  async function handleError() {
    notifications.notifyError(
      'Something went wrong submitting your responses. Please try again later.'
    )
    navigate(paths.ASSESSMENTS, { replace: true })
  }

  const videoButton = hasVideoEnded ? (
    <Button
      color="white"
      icon={<ArrowCircleSvg />}
      iconPosition="right"
      level="text"
      className={styles['video-button']}
      onClick={handlePlayClick}>
      <Text className={styles['video-button--copy']}>Replay video</Text>
    </Button>
  ) : (
    <Button
      color="yellow"
      size="xlarge"
      className={styles['video-button']}
      onClick={handlePlayClick}>
      Watch video
    </Button>
  )

  const buttonText = 'Start assessment'

  const head = (
    <Head
      title={`Test your ${assessment.title.toLowerCase()} in minutes with a free, online test from Bold`}
      description={`Test your ${assessment.title.toLowerCase()} in minutes and learn exercises to improve ${assessment.title.toLowerCase()}. We teach exercises that improve ${assessment.title.toLowerCase()} that can be done anywhere.`}
    />
  )

  const commonProps = {
    ...props,
    className: classNames('AssessmentLanding', styles.this, className),
  }

  if (isMovementAssessment) {
    return (
      <Layout {...commonProps}>
        {head}
        <View color="grey">
          <Container flush size="small">
            <OnboardingCheckinSingleForm
              checkinTracker={checkinTracker}
              onSuccess={handleSuccess}
              onError={handleError}
            />
          </Container>
        </View>
      </Layout>
    )
  }

  return (
    <Layout {...commonProps} footer={false}>
      {head}
      <div className={styles.inner}>
        <div className={styles.video}>
          {showVideo ? (
            <Video2 url={assessment.video} showPlayIcon playing onEnded={handleVideoEnded} />
          ) : (
            <>
              <Image src={assessment.photo2x} className={styles.cover} />
              {videoButton}
            </>
          )}
        </div>
        <Text align="center" element="section" className={styles.content}>
          <div className={styles['content--body']}>
            {!user.isEnterpriseOctopusUser(globalContext.user) && (
              <Row size="small">
                <Text color="white" element="div" className={styles.icon}>
                  <assessment.icon />
                </Text>
              </Row>
            )}
            <Row size="xsmall">
              <Text casing="upper" element="h1" size="xlarge" weight="semibold">
                {assessment.title} assessment
              </Text>
            </Row>
            {!hasVideoEnded ? (
              <>
                <Text element="p">
                  {!user.isEnterpriseOctopusUser(globalContext.user) && assessment.intro} Your Bold
                  Trainer {assessment.trainer.split(' ')[0]} explains how to conduct the assessment,
                  then you can try it for yourself.
                </Text>
                <Text element="p">Watch the video to continue.</Text>
              </>
            ) : (
              <>
                <Text element="p">
                  Now it’s your turn to try the assessment! You will have the chance to get set up
                  and situated with your chair.
                </Text>
                <Text element="p">Click ‘{buttonText}’ to begin.</Text>
              </>
            )}
          </div>
          {hasVideoStarted && (
            <Button
              to={`${paths.ASSESSMENTS}${assessmentInternalId}/${paths.ASSESSMENT_EXERCISE_RELATIVE}`}
              color="yellow"
              full
              size="xlarge">
              {buttonText}
            </Button>
          )}
        </Text>
      </div>
      <ModalConfirm
        isOpen={showRetakeModal}
        confirmText="Take my assessment"
        confirmButtonProps={{
          color: 'purple',
        }}
        hideCancel
        size="small"
        onConfirm={closeRetakeModal}
        onRequestClose={closeRetakeModal}>
        <Copy align="left">
          <Text element="p">
            Wow, time flies when you’re building strength balance and mobility! It’s time to retake
            your balance assessment. Please hit the button below to complete your 1-minute balance
            assessment.
          </Text>
        </Copy>
      </ModalConfirm>
    </Layout>
  )
}
